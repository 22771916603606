Header .nav-link {
  color: var(--font-color);
  font-size: 18px !important;
  font-weight: 500 !important;
}

.headerTop { 
  top: 0px;
  width: 100%;
  /* background-color: #fff; */
  color:  var(--primary-color);
  margin-bottom: 30px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 5px solid var(--primary-color);
  border-top: none;
}

.hdrTop {
  --bs-gutter-x: 0rem !important;
  color:  #000;
  font-size: 16px;
  font-weight: bold;
}

.navbar {
  background-color: var(--primary-color);
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 5px !important;
  margin-top: 10px;
}

.navCart {
  color: #fff;
}

.navCartIcon {
  fill: #000 !important;
  cursor: pointer;
}

.cartFooter {
  font-size: 1rem;
}

.navCartBtnClose {
  font-family: var(--primary-family);
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.5rem;
  cursor: pointer;
  right: 15px;
  position: absolute;
  top: 5px;
}

.headerLogoLevel {
  height: 150px;
  padding-top: 20px;
  font-size: 16px;
}

Header .nav-link:hover {
  color: rgba(179, 179, 179, 0.692) !important;
}
Header a {
  /* margin: 0px 20px !important; */
}

Header .navbar-toggler {
  color: var(--primary-color);
}

Header .header-img {
  width: 100%;
}

.logo {
  /* animation: App-logo-spin infinite 50s linear; */
}

@media (max-width: 545px) {
  .logo {
    width: 80px;
  }
}
